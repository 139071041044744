/**
 * SUG JavaScript Utility
 * @author SUG
 **/
utility = function(){

	/**
	 * getCookie() - return value of a cookie.
	 * @cookiename - the name of cookie to evaluate
	 **/
	var getCookie = function(cookiename){
		var name = cookiename + "=";
	    var ca = document.cookie.split(';');
	    for(var i=0; i<ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' '){
	        	c = c.substring(1);
	        	if ( c.indexOf(name) != -1 )
	        		return c.substring(name.length,c.length);
	        }
	    }
	    return "";
	};

	/**
	 * deleteCookie() - void
	 * @cookiename - the name of cookie to be deleted
	 **/
	var deleteCookie = function(cookiename) {
	    document.cookie = cookiename + '=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	};

	/**
	 * setCookie() - void
	 * @cookiename - the name of cookie to be added
	 * @cookieval - the value of the cookie
	 * @expiration - the expiration date of the cookie
	 **/
	var setCookie = function(cookiename,cookieval,expiration){
		if( getCookie(cookiename) != '' )
			deleteCookie(cookiename);
		var now = new Date();
		var time = now.getTime() + 1000 * 1800; // default 30 minutes
			now.setTime(time);
		var expTime = '; expires=' + now.toGMTString();
		if( typeof expiration !== 'undefined' && expiration instanceof Date )
			expTime = '; expires=' + expiration.toGMTString();
		var val = typeof cookieval !== 'undefined' && typeof cookieval === 'object' ? JSON.stringify(cookieval) : cookieval;
		document.cookie = [cookiename, '=', val, expTime, '; SameSite=None; Secure', '; path=/'].join('');

		if( getCookie(cookiename) == val )
			return true;
		else
			return false;
	};

	/**
	 * isCookieEnabled - return boolean, checks if client's browser cookie is enabled
	 **/
	var isCookieEnabled = function(){
		try {
			return navigator.cookieEnabled;
		} catch(e){ return false; }
	};

	/**
	 * getLocation() - uses geolocation
	 * @success - success callback method, default returns position object
	 * @error - error callback method, default returns error string
	 * methodHandler - method that will handle the result. Required handler when success and error handlers are not provided
	 **/
	var getLocation = function(success,error,methodHandler){
		// default success callback
		function thisSuccess(position) {
			methodHandler(position);
		}

		// default error callback
		function thisError(error){
			var result;
		    switch(error.code) {
		        case error.PERMISSION_DENIED:
		            result = "User denied the request for location request.";
		            break;
		        case error.POSITION_UNAVAILABLE:
		        	result = "Location information is not available.";
		            break;
		        case error.TIMEOUT:
		        	result = "Request timed out.";
		            break;
		        case error.UNKNOWN_ERROR:
		        	result = "An unknown error occurred.";
		            break;
		    }
		    methodHandler(result);
		}

		var successCallback = typeof success != 'undefined' ? success : thisSuccess;
		var errorCallback = typeof error != 'undefined' ? error : thisError;
		if(navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
		} else {
			return 'Geolocation is not supported in this browser.';
		}

	};

	var validateEmail = function( email ){
		var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return ( regExp.test( email) );
	};

	var getUrlParamValue = function(param){
		try {
			param = param.toString().toLowerCase();
			var urlParams = window.location.search;
				urlParams = urlParams.toString().replace('?','').split('&');
			var stcParams = {};
			var temp = [];
			for( var i in urlParams ){
				temp = urlParams[i].split('=');
				stcParams[temp[0].toString().trim().toLowerCase()] = temp[1];
			}
			return param in stcParams ? stcParams[param] : '';
		} catch( e ){
			return '';
		}
	};

	/**
	 * Returns a object of all current URL params
	 * If no URL params exist, an empty object is returned
	 **/
	var getAllUrlParams = function(){
		var params = {};
		try {
			if( window.location.search.length > 0){
				var arrParams = window.location.search.split('?')[1].split('&');

				for(var i = 0; i < arrParams.length; i++){
						var pair = arrParams[i].split('=');
						params[ pair[0] ] = decodeURI( pair[1] );
				}
			}
      return params;
		} catch( e ){
			return params;
		}
	};

	var lowerCaseKeys = function( data ){
		if (Array.isArray( data )){ // in case there are arrays of objects
			var length = data.length;
			var tempArray = [];

			while (length--){
				tempArray[length] = lowerCaseKeys(data[length]);
			}

			return tempArray;
		} else if (typeof data === 'object' && data !== null){ // this section actually changes the keys

			var keyList = Object.keys(data); // array of keys
			var amountOfKeys = keyList.length;
			var newObject = {}; // stores the new lowercase object

			while( amountOfKeys-- ){
				var key = keyList[amountOfKeys];
				var newKey = key.toLowerCase();
				newObject[newKey] = data[key];

				if( Array.isArray( newObject[newKey] ) || typeof newObject[newKey] === 'object') {
					newObject[newKey] = lowerCaseKeys(newObject[newKey]);
				}
			}

			return newObject;

		} else {
			return data; // no need to change anything here
		}
	};

	var randomStringGenerator = function(length) {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for(var i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	};

	var isMobile = function(){
	    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
	        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))){
	        return true;
		}
		return false;
	};

	/** return object **/
	return {
		getCookie : getCookie,
		deleteCookie : deleteCookie,
		setCookie : setCookie,
		isCookieEnabled : isCookieEnabled,
		getLocation : getLocation,
		validateEmail : validateEmail,
		getUrlParamValue : getUrlParamValue,
		getAllUrlParams : getAllUrlParams,
		lowerCaseKeys : lowerCaseKeys,
		randomStringGenerator : randomStringGenerator,
		isMobile : isMobile
	};

}();

/** polyfill prototype functions for backwards compatibility **/
(function(){
	// https://tc39.github.io/ecma262/#sec-array.prototype.find
	if (!Array.prototype.find) {
	  Object.defineProperty(Array.prototype, 'find', {
	    value: function(predicate) {
	     // 1. Let O be ? ToObject(this value).
	      if (this == null) {
	        throw new TypeError('"this" is null or not defined');
	      }

	      var o = Object(this);

	      // 2. Let len be ? ToLength(? Get(O, "length")).
	      var len = o.length >>> 0;

	      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
	      if (typeof predicate !== 'function') {
	        throw new TypeError('predicate must be a function');
	      }

	      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
	      var thisArg = arguments[1];

	      // 5. Let k be 0.
	      var k = 0;

	      // 6. Repeat, while k < len
	      while (k < len) {
	        // a. Let Pk be ! ToString(k).
	        // b. Let kValue be ? Get(O, Pk).
	        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
	        // d. If testResult is true, return kValue.
	        var kValue = o[k];
	        if (predicate.call(thisArg, kValue, k, o)) {
	          return kValue;
	        }
	        // e. Increase k by 1.
	        k++;
	      }

	      // 7. Return undefined.
	      return undefined;
	    },
	    configurable: true,
	    writable: true
	  });
	}
}());

//from: https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/after()/after().md
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('after')) {
      return;
    }
    Object.defineProperty(item, 'after', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function after() {
        var argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function (argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.parentNode.insertBefore(docFrag, this.nextSibling);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
