/**
	 * getCookie() - return value of a cookie.
	 * @cookiename - the name of cookie to evaluate
	 **/
 var getCookie = function(cookiename){
	var name = cookiename + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i<ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0)==' '){
			c = c.substring(1);
			if ( c.indexOf(name) != -1 )
				return c.substring(name.length,c.length);
		}
	}
	return "";
};

/**
 * Pre-Process and hash email - return value of an array of email hashes.
 * @email - the email address to process and hash
 **/
// Pre-Process and hash email
var processEmail = function (email) {
	var emailHashes = {};
	var hashedEmails = [];
	var regexObj = {
		whitespaceReplace: /\s+/g,
		google: {
			dotReplace: /\.(?=.*?@gmail\.com)/,
			aliasReplace: /\+[^)]*@/
		}
	};

	// purge fake email domains
	if (email.length && (email.indexOf("@example.com") > -1 || email.indexOf("@test.com") > -1 || email=='test@gmail.com' || email=='testing@gmail.com' || email=='tester@gmail.com') ) {
		// Set fake email addressed to empty so no processing will be done on them.
		email = "";
	}

	if (email.length) {
		// Remove all whitespace and lowercase email address
		email = email.replace(regexObj.whitespaceReplace, '');
		email = email.toLowerCase();

		var valid = new RegExp (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email) ? true : false;

		if (valid) {
			// Extra processing needed for google emails
			if (email.indexOf("@gmail.com") > -1) {
				// remove dots within name
				email = email.replace(regexObj.google.dotReplace,'');
				// remove + aliases
				email = email.replace(regexObj.google.aliasReplace,'@');
			}
			// new MD5 instance
			var MD5 = new Hashes.MD5();
			// new SHA1 instance
			var SHA1 = new Hashes.SHA1();
			// new SHA256 instance
			var SHA256 =  new Hashes.SHA256();
			// Hash Email Here
			emailHashes['md5'] = MD5.hex(email);
			emailHashes['sha1'] = SHA1.hex(email);
			emailHashes['sha256'] = SHA256.hex(email);
			hashedEmails = [emailHashes['md5'], emailHashes['sha1'], emailHashes['sha256']];
		}

	}
	return hashedEmails;
};

window.startAts = function(email) {
	// Only run LiveRamp ATS code if the LiveRamp ATS feature is active
	if (window.globalcfg.lr) {
		// JSON parse string to convert to true boolean
		var euUser = JSON.parse(window.globalcfg.isEU_User);

		// Only handle LiveRamp ATS code if user is not from EU
		if (!euUser) {
			var hashes = processEmail(email);
			
			// CCPA
			var usprivacystring = getCookie('usprivacy');
			var atsScript = document.createElement('script');
			atsScript.src = 'https://ats.rlcdn.com/ats.js';
			var payload = {
				"placementID": 13401,
				"rootDomain": "signupgenius.com",
				"ccpaConsentString": usprivacystring,
				"emailHashes": hashes,
			};
			if (window.globalcfg.isTest) {
				payload.logging = "debug";
			}

			atsScript.onload = function() {
				window.ats.start(payload);
			};
			document.body.appendChild(atsScript);
		}
	}
};




